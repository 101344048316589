.altai-theme-content #bodyView .panel-comeos {
  min-height: 100px;
}
.altai-theme-content #bodyView .panel-comeos-single {
  clear: both;
}
.altai-theme-content #bodyView .panel-comeos-single-light {
  background-color: #f9f9f9;
  color: #333;
}
.altai-theme-content #bodyView .panel-comeos-single-light-contrast {
  background-color: #f2f2f2;
  color: #222;
}
.altai-theme-content #bodyView .panel-comeos-single-dark {
  background-color: #222;
  color: #f2f2f2;
}
.altai-theme-content #bodyView .panel-comeos-single-dark-contrast {
  background-color: #333;
  color: #f9f9f9;
}
.altai-theme-content #bodyView .right-bar {
  /*border-right: 2px solid #eaeaea;*/
}
.altai-theme-content #bodyView .panel-hide-empty section {
  padding-top: 0;
  padding-bottom: 0;
}
.altai-theme-content #bodyView .panel-hide-empty section .container {
  padding-bottom: 0;
}
